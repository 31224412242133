export const headerText = {
    title1: "Anxiety Relief:",
    title2: "Find Your Calm",
    subtitle: "Take control of your anxiety with an app thoughtfully designed from personal experience"
}

export const about1Text = {
    title: "6 Calming Tools",
    body: "The app features six calming tools including breathing exercises, motivational quotes and affirmations. Add your favourite photos to your happy place, and save a friend's contact details to phone quickly when in need.",
    body2: "Finally, a gratitude and positivity journal provides a great way to develop a positive mindset."
}

export const about2Text = {
    title: "Widgets and More",
    body: "Anxiety Relief: Find Your Calm uses some of the latest features of iOS to make accessing the tools as easy as possible. Add motivational quotes, your affirmations or a quick-launcher to your homescreen with widgets to always have access to the tools you love.",
    body2: "The app integrates seamlessly with Siri, allowing you to ask Siri to open the tools when you need to access them quickly."
}

export const freeText = {
    part1: "And the best part is... ",
    part2: "it's 100% free, forever."
}

export const bioText = {
    title: "Created by Ben Robinson",
    body: "I'm Ben Robinson - a student learning software development for Apple's platforms, the web and the server. After my own experience with anxiety, I decided to build a free app with tools that helped me in the hope they would also help others."
}

export const helmetText = {
    title: "Anxiety Relief: Find Your Calm iOS App",
    description: headerText.subtitle,
    privacyTitle: "Anxiety Relief | Privacy Policy",
    supportTitle: "Anxiety Relief | Support",
    termsTitle: "Anxiety Relief | Ts & Cs",
    error404Title: "404 | Page Not Found"
}

export const appClipsText = {
    breathing: {
        title: "Try breathing",
        subtitle: "Requires iOS 14 or later",
        link: "https://anxietyrelief.benrobinson.dev/tools/breathing"
    },
    quotes: {
        title: "View quotes",
        subtitle: "Requires iOS 14 or later",
        link: "https://anxietyrelief.benrobinson.dev/tools/quotes"
    }
}