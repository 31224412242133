import * as React from "react";
import {Helmet} from "react-helmet";
import {helmetText} from "../helper/text";

export default function HelmetHeader(props) {
    return (
        <Helmet>
            <title>{props.title}</title>
            <meta name="description" content={helmetText.description}/>
            <meta name="theme-color" content={props.blueTheme ? "#2e8cc7" : "#ffffff"}/>
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
            <link rel="manifest" href="/site.webmanifest"/>
            <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#3390f6"/>
            <meta name="msapplication-TileColor" content="#da532c"/>
            <meta name="theme-color" content="#ffffff"/>
            <script data-host="https://microanalytics.io" data-dnt="false" src="https://microanalytics.io/js/script.js"
                    id="ZwSg9rf6GA" async defer/>
        </Helmet>
    );
}