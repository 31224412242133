import { extendTheme } from "@chakra-ui/react"

const theme = extendTheme({
    colors: {
        brand: {
            100: "#2e8cc7",
            200: "#2e8cc7",
            300: "#2e8cc7",
            400: "#2e8cc7",
            500: "#2e8cc7",
            600: "#2e8cc7",
            700: "#317dce",
            800: "#317dce",
            900: "#317dce"
        }
    }
});

export default theme;