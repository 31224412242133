const homePage = "https://anxietyrelief.benrobinson.dev/";

export const homeLink = homePage;
export const privacyLink = homePage + "privacy";
export const termsLink = homePage + "terms";
export const supportLink = homePage + "support";

export const downloadLink = "https://apps.apple.com/gb/app/anxiety-relief-find-your-calm/id1460568970";

export const mySiteLink = "https://benrobinson.dev"
export const myGitHubLink = "https://github.com/benrobinson16";
export const myEmailLink = "mailto:hello@benrobinson.dev";
export const myLinkedInLink = "https://www.linkedin.com/in/benrobinson16";